import { gql } from 'graphql.macro'

export const GET_BASE_DATA = gql`
  query {
    app @client {
      baseParams {
        activeClassTreeOptionValue
        activeClassTreeNodeIds
      }
    }
  }
`

export const GET_CLASSES_LEVELS_LIST = gql`
  query GetModuleClasses($moduleId: Int, $submoduleKey: String!) {
    submoduleByModuleIdAndKey(moduleId: $moduleId, key: $submoduleKey) {
      id
      key
      submoduleTrees {
        classTree {
          hasTogglesPerLevel
          key
          i18nStrings {
						language
						stringValue
          }
        }
      }
    }
  }
`

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
				i18nStrings {
					language
					stringValue
				}
        classTreeNode {
          classe {
            classesTooltips {
              id
            }
          }
        }
      }
    }
  }
`;
