import React, { useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import parseToken from '../../../../../../../../helpers/parseToken';
import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import toast from '../../../../../../../../utils/toast';

import styles from './LoginForm.module.scss';

const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;

const initialState = {
  login: '',
  password: '',
};

export default function LoginForm({
  onRecoveryPassword = () => {}
}) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { login, password } = state;

    const payload = { email: login, password };

    fetch(`${ API_URL }/v1/tokens`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        if (_.get(data, 'success')) {
          const id = _.get(data, 'id');
          const token = _.get(data, 'token');

          if (token) {
            localStorage.setItem('tokenId', id);
            parseToken(token);
            window.location.href = '/?login=true';
          }
        } else {
          toast(_.get(data, 'message') || formatMessage('mapbiomas.toast.error'), 'error');
        }
      })
      .catch((error) => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  return (
    <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          required
          id="login-field"
          label={ formatMessage('mapbiomas.user.login.fields.email') }
          type="text"
          variant="outlined"
          size="small"
          value={ state.login }
          onChange={ handleTextChange.bind(this, 'login') }
        />
      </FormControl>
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          required
          id="password-field"
          label={ formatMessage('mapbiomas.user.login.fields.password') }
          type="password"
          variant="outlined"
          size="small"
          value={ state.password }
          onChange={ handleTextChange.bind(this, 'password') }
        />
      </FormControl>
      <div className={ styles.actionsWrapper }>
        <a className={ styles.helperLink } onClick={ onRecoveryPassword }><FormattedMessage id="mapbiomas.user.login.forgot_password" /></a>
        <Button
          variant="contained"
          color="primary"
          size="small"
          type="submit"
        >
          <FormattedMessage id="mapbiomas.user.login.buttons.login" />
        </Button>
      </div>
    </form>
  )
}