import React, { Fragment } from 'react'
import _ from 'lodash';
import classnames from 'classnames';
import {useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import InfoTooltip from '../../../../../../../../components/InfoTooltip';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './ClassTreesControl.module.scss';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    color: `${ theme.palette.primary.main } !important`,
    borderColor: `${ theme.palette.primary.main } !important`
  },
  link: {
    color: '#16a085 !important',
  },
}));

const mapSubmoduleKeyToTooltip = {
  'fire_annual': '<b>Total Queimado</b> - toda a área queimada sobre ambiente antrópico ou natural, para o ano selecionado.<br/><br/><b>Classe</b> - área queimada com a cor da classe de cobertura e uso do solo que foi queimada.',
  'fire_monthly': 'Área queimada com a cor da classe de cada um dos 12 meses do ano selecionado.',
  'fire_accumulated': 'Toda a área queimada acumulada sobre ambiente antrópico ou natural, para o período selecionado.',
  'fire_frequency': 'Área queimada com a cor da classe de frequência de ocorrência de fogo, sendo 1 as áreas que queimaram uma única vez, 2 as classes que queimaram duas vezes, etc.',
};

export default function ClassTreesControl({
  classTreeOptions,
  activeTreeOption,
  levels = [],
  activeLevels = [],
  submoduleKey,
  hideLevelsControl = false,
  onActiveTreeOptionChange,
  onLevelToggle = () => {},
}) {
  const classes = useStyles()
  const formatMessage = useFormatMessage()
  const legendTooltip = mapSubmoduleKeyToTooltip[submoduleKey];

  const intl = useIntl()

  if (!activeTreeOption || !classTreeOptions) {
    return null
  }

  const handleOptionChange = (event) => {
    const value = _.get(event, 'target.value');
    const option = _.find(classTreeOptions, { value });
    onActiveTreeOptionChange(option);
  };

  const renderDefaultClassesPdfTable = () => (
    <p className={ styles.descriptionText }>
      <FormattedMessage id="mapbiomas.header.classes_control.description.click" />
      {' '}
      <a href={intl.formatMessage({id: 'mapbiomas.header.classes_control.description.url'})} target="noopener noreferrer _blank" className={ classes.link }>
        <FormattedMessage id="mapbiomas.header.classes_control.description.here" />
      </a>
      {' '}
      <FormattedMessage id="mapbiomas.header.classes_control.description.content" />
    </p>
  );

  const renderOptions = () => {
    if (_.size(classTreeOptions) <= 3) {
      return (
        <div className={ classnames(styles.toggleButtons, {
          [styles.toggleButtons2Items]: _.size(classTreeOptions) === 2,
          [styles.toggleButtons3Items]: _.size(classTreeOptions) === 3
        }) }>
          { _.map(classTreeOptions, (classTreeOption) => {
            return (
              <button
                className={ classnames(styles.actionButton, {
                  [classes.activeButton]: activeTreeOption.value === classTreeOption.value
                }) }
                key={ `view-mode-${ classTreeOption.value }` }
                onClick={ _.partial(onActiveTreeOptionChange, classTreeOption) }
              >
                { classTreeOption.label }
              </button>
            )
          }) }
        </div>
      );
    } else {
      return (
        <FormControl variant="outlined" className={ styles.formControlSelectInput }>
          <Select
            value={ activeTreeOption.value }
            onChange={ handleOptionChange.bind(this) }
          >
            { _.map(_.orderBy(classTreeOptions, 'label', 'asc'), (classTreeOption) => {
              return (
                <MenuItem value={ classTreeOption.value }>{ classTreeOption.label }</MenuItem>
              )
            }) }
          </Select>
        </FormControl>
      );
    }
  };

  const renderLevelsControl = () => {
    return _.map(levels, (levelId) => {
      const isChecked = _.includes(activeLevels, levelId);

      return (
        <FormControlLabel
          key={ `level-control-${ levelId }` }
          classes={{
            root: styles.checkboxRoot,
            label: styles.checkboxLabel,
          }}
          control={
            <Checkbox
              checked={ isChecked }
              onChange={ onLevelToggle.bind(this, levelId) }
              name={ `level-${ levelId }` }
              color="primary"
              size="small"
            />
          }
          label={ `${ formatMessage('mapbiomas.header.classes_control.level.title') } ${ levelId }` }
        />
      );
    })
  };

  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.title }>
        <FormattedMessage id="mapbiomas.header.classes_control.title" />
        { legendTooltip && <InfoTooltip text={ legendTooltip } placement="right-end" /> }
      </h2>
      { activeTreeOption.value === 'default' && renderDefaultClassesPdfTable() }
      { classTreeOptions.length > 1 &&
        <Fragment>
          <span className={ styles.label }>
            <FormattedMessage id="mapbiomas.header.classes_control.view_mode.title" />
          </span>
          { renderOptions() }
        </Fragment>
      }
      { !_.isEmpty(levels) && _.size(levels) > 1 && !hideLevelsControl && renderLevelsControl() }
    </div>
  )
}
