import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import TransitionMatrixTable from './TransitionMatrixTable';
import SankeyChart from '../../components/SankeyChart';

import TabList from '../../../../../../components/TabList';

import SankeyChartControl from './components/SankeyChartControl';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
  GET_CLASSES_DATA,
} from './query';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './DashboardDialogTransitionContent.module.scss';

const levelIds = [1, 2];

export default function DashboardDialogTransitionContent({
  baseParams,
  onLinkClick = () => {},
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const formatMessage = useFormatMessage();
  const [activeLevel, setActiveLevel] = useState(1);
  const [activeTab, setActiveTab] = useState('sankey');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');
  const yearRange = _.get(baseParams, 'yearRange');
  const [fromYear, toYear] = _.split(yearRange, '-');
  const { data: defaultClassTreeData, loading: loadingClassesData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    }
  });
  const defaultClassTreeLevelsList = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const defaultClassTreeId = _.get(defaultClassTreeData, 'classTreeByKey[0].id');
  // const { data: classesData, loading: loadingClassesData } = useQuery(GET_CLASSES_DATA, {
  //   client: coverageApolloClient
  // });
  // const defaultClassTree = _.find(_.get(classesData, 'classTreesList'), { key: 'default' });
  // const defaultClassTreeId = _.get(defaultClassTree, 'id');
  // const defaultClassTreeLevelsList = _.get(defaultClassTree, 'classTreeLevelsList');

  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      level: _.range(1, activeLevel + 1),
      territoryIds: territoryIds,
      fromYear: parseInt(fromYear),
      toYear:  parseInt(toYear),
      classTreeId: defaultClassTreeId,
    },
    skip: _.isEmpty(territoryIds) || !yearRange || loadingClassesData || !defaultClassTreeId
  });

  const handleLevelChange = (event) => {
    setActiveLevel(Number(event.target.value));
  };

  const handleSankeyClick = (data) => {
    if (_.get(data, 'type') === 'link') {
      const { source, target } = data;
      const sourceTreeNodeId = _.get(source, 'treeNodeId');
      const targetTreeNodeId = _.get(target, 'treeNodeId');
      const parsedId = (sourceTreeNodeId * 100) + targetTreeNodeId;

      onLinkClick(parsedId);
    }
  };

  const renderLoading = () => {
    return (
      <div className={ styles.loadingWrapper }>
        <CircularProgress />
      </div>
    );
  };

  const renderTabContent = () => {
    const coverageClassificationChangesDataByTreesList = _.get(baseData, 'coverageClassificationChangesDataByTreeList');

    if (_.isNil(coverageClassificationChangesDataByTreesList)) {
      return null;
    }

    const uniqData = _.uniqBy(coverageClassificationChangesDataByTreesList, (obj) => `${ obj.fromTreeNodeId }-${ obj.toTreeNodeId }-${ obj.fromYear }-${ obj.toYear }`);
    const aggregatedData = _.values(_.reduce(uniqData, (result, obj) => {
      const key = `${ obj.fromTreeNodeId }-${ obj.toTreeNodeId }-${ obj.fromYear }-${ obj.toYear }`;
      const nextValue = result[key] ? result[key].areaInHa + obj.areaInHa : obj.areaInHa;

      result[key] = {
          ...obj,
          areaInHa: nextValue
      };

      return result;
    }, {}));

    const parsedData = _(aggregatedData)
      .map((item) => {
        const fromClassData = _.find(defaultClassTreeLevelsList, { id: _.get(item, 'fromTreeNodeId') });
        const toClassData = _.find(defaultClassTreeLevelsList, { id: _.get(item, 'toTreeNodeId') });
        const fromYear = _.get(item, 'fromYear');
        const toYear = _.get(item, 'toYear');

        const fromPositionLevelLabel = `${ _.join(_.get(fromClassData, 'positionInTree'), '.') }`;
        const fromNodeId = `${ fromPositionLevelLabel }-${ fromYear }-${ _.get(fromClassData, 'id') }`;
        const fromStringList = _.get(fromClassData, 'i18nStrings');
        const fromSelectedString = _.find(fromStringList, { language: locale });
        const fromNode = {
          type: 'node',
          id: fromNodeId,
          treeNodeId: _.get(fromClassData, 'id'),
          level: _.get(fromClassData, 'level'),
          label: `${ fromPositionLevelLabel }. ${ _.get(fromSelectedString, 'stringValue') }`,
          color: _.get(fromClassData, 'color'),
        };
        const toPositionLevelLabel = `${ _.join(_.get(toClassData, 'positionInTree'), '.') }`;
        const toNodeId = `${ toPositionLevelLabel }-${ toYear }-${ _.get(toClassData, 'id') }`;
        const toStringList = _.get(toClassData, 'i18nStrings');
        const toSelectedString = _.find(toStringList, { language: locale });
        const toNode = {
          type: 'node',
          id: toNodeId,
          treeNodeId: _.get(toClassData, 'id'),
          level: _.get(toClassData, 'level'),
          label: `${ toPositionLevelLabel }. ${ _.get(toSelectedString, 'stringValue') }`,
          color: _.get(toClassData, 'color'),
        };
        const link = {
          type: 'link',
          source: fromNodeId,
          target: toNodeId,
          value: _.get(item, 'areaInHa'),
        };

        if (fromClassData && toClassData) {
          return [fromNode, toNode, link];
        } else {
          return [];
        }
      })
      .flatten()
      .value();

    const nodes = _.orderBy(_.uniqBy(_.filter(parsedData, { type: 'node' }), 'id'), ['label'], ['asc']);
    const links = _.orderBy(_.filter(parsedData, { type: 'link' }), ['source', 'target'], ['asc', 'asc']);

    if (_.isEmpty(nodes) || _.isEmpty(links)) {
      return null;
    }

    if (activeTab === 'sankey') {
      return (
        <SankeyChartControl
          activeLevel={ activeLevel }
          fromYear={ fromYear }
          toYear={ toYear }
          nodes={ nodes }
          links={ links }
          onClick={ handleSankeyClick }
        />
      );
    } else {
      return (
        <TransitionMatrixTable
          fromYear={ fromYear }
          toYear={ toYear }
          nodes={ nodes }
          links={ links }
        />
      );
    }
  };

  const renderContent = () => {
    return (
      <div className={ styles.content }>
        <RadioGroup
          name="level"
          value={ activeLevel }
          onChange={ handleLevelChange }
          className={ styles.radioGroup }
        >
          { _.map(levelIds, (level) => {
            return (
              <FormControlLabel
                key={ `level-control-${ level }` }
                classes={ {
                  label: styles.radioLabel
                } }
                value={ level }
                control={ <Radio size="small" color="primary" className={ styles.radioGroupInput } /> }
                label={ `${ formatMessage('mapbiomas.dashboard.coverage.coverage_changes.levels_control.label') } ${ level }` }
              />
            );
          }) }
        </RadioGroup>
        <div className={ styles.tabListWrapper }>
          <TabList
            itemSizes={{ xs: 4 }}
            items={ [
              { id: 'sankey', label: formatMessage('mapbiomas.dashboard.coverage.coverage_changes.view_mode.sankey') },
              { id: 'matrix', label: formatMessage('mapbiomas.dashboard.coverage.coverage_changes.view_mode.matrix') },
            ] }
            value={ activeTab }
            onChange={ setActiveTab }
          />
        </div>
        <div className={ styles.dataWrapper }>
          { renderTabContent() }
        </div>
      </div>
    );
  };

  if (loadingBaseData) {
    return renderLoading();
  } else if (!loadingBaseData && territories) {
    return renderContent();
  } else {
    return null;
  }
}
