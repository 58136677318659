export default [
  {
    index: 0,
    key: "pampa_limit",
    labelKey: "pampa_limit",
    color: "#B14018",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:pampa_limit"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptación baseada em Soriano et al. 1992, IBGE 2019 y Oyarzabal et al. 2018' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/pampa_limit.zip'
  },
  {
    index: 1,
    key: "country",
    labelKey: "country",
    color: "#B024E0",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptación en base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR) y IDEUy, 2022 (UY)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/countries.zip'
  },
  {
    index: 2,
    key: "political_level_1",
    labelKey: "political_level_1",
    color: "#3A3D3A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:political_level_1"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptación en base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR) y IDEUy, 2022 (UY)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/state_departamento.zip'
  },
  {
    index: 3,
    key: "political_level_2",
    labelKey: "political_level_2",
    color: "#F4B615",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:political_level_2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptación em base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR), Ministerio de Ganadería, Agricultura y Pesca, 2011 (UY)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/departamento_municipio_enumeration.zip'
  },
  {
    index: 4,
    key: "watershed",
    labelKey: "watershed",
    color: "#16DFF2",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:watershed"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INTA, 2002 (AR), ANA, 2019 (BR), Ministério de Ambiente, 2017 (UY)' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/watersheds.zip'
  },
  {
    index: 5,
    key: "phytogeography",
    labelKey: "phytogeography",
    color: "#FDAE61",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:phytogeography"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Oyarzabal et al. 2018' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/phytogeography.zip'
  },
  {
    index: 6,
    key: "protected_areas",
    labelKey: "protected_areas",
    color: "#1A7836",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INTA, 2002(AR), MMA, 2019 (BR), Ministério de Ambiente, 2017 (UY)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/protected_areas.zip'
  },
  {
    index: 7,
    key: "quilombola_territories",
    labelKey: "quilombola_territories",
    color: "#C51B7D",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:quilombola_territories"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'FUNAI' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/quilombola_territories.zip'
  },
  {
    index: 8,
    key: "indigenous_territories",
    labelKey: "indigenous_territories",
    color: "#FF0100",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-pampa-col3:indigenous_territories"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INCRA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_3/download/layers/indigenous_territories.zip'
  }
];
